import { Col, Row } from "react-bootstrap"

import GlobalClasses from "../../styles/global"
import { HomepageBlogPost } from "../../types/Common"
import { Link } from "gatsby"
import React from "react"
import { createUseStyles } from "react-jss"
import { useTranslation } from "react-i18next"

const useStyles = createUseStyles(theme => ({
  ...GlobalClasses,
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  flexBox: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignContent: "flex-start",
    alignItems: "stretch",
    margin: "30px 0px 30px 0px",
  },

  title: {
    color: theme.colors.darkGrey,
    fontSize: "24px",
    fontWeight: 600,
    letterSpacing: "1px",
    textTransform: "uppercase",
    padding: "0px 15px 0px 15px",
  },

  grid: {
    margin: 0,
    padding: 0,
    maxWidth: "1160px",
    width: "100%",
    marginTop: "20px",
    marginBottom: "30px",
  },

  noPaddingMargin: {
    margin: 0,
    padding: 0,
  },

  btnVisit: {
    width: "232px",
    height: "46px",
    fontSize: "13px",
    paddingTop: "16px",

    "& i:before": {
      fontSize: "18px",
    },

    "&:first-child": {
      marginBottom: "22px",
    },
  },
}))

const useTileStyles = createUseStyles(theme => ({
  ...GlobalClasses,
  main: backgroundPicture => ({
    maxWidth: "370px",
    margin: "8px",
    background: `url(${backgroundPicture})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderRadius: "10px",
    height: "359px",
    display: "flex",
    flexDirection: "column-reverse",
    "&:hover": {
      textDecoration: "none",
      boxShadow:
        "0 4px 5px -2px rgba(0,0,0,.2),0 7px 10px 1px rgba(0,0,0,.14),0 2px 16px 1px rgba(0,0,0,.12)!important",
      cursor: "pointer",
    },
  }),
  item: {
    margin: "0px 15px 15px 15px",
    backgroundColor: "#f3f4f7",
    borderRadius: "10px",
    minHeight: "184px",
    padding: "15px 25px 15px 25px",
    "&:hover": {
      textDecoration: "none",
      cursor: "pointer",
    },
  },
  title: {
    color: theme.colors.darkGrey,
    fontSize: "18px",
    fontWeight: 600,
    letterSpacing: "0.75px",
    height: 54,
    textTransform: "inherit",
  },

  description: {
    color: theme.colors.darkGrey,
    fontSize: "13px",
    fontWeight: 400,
    letterSpacing: "0.54px",
    marginTop: "10px",
    height: "57px",
  },

  date: {
    color: theme.colors.darkGrey,
    fontSize: "12px",
    fontWeight: 400,
    letterSpacing: "0.50px",
    marginTop: "15px",
  },

  noTextDecoration: {
    textDecoration: "none !important",
    "&:hover": {
      textDecoration: "none !important",
    },
  },
}))

type DataProps = {
  blockPosts: HomepageBlogPost[]
}

const BlockPanel: React.ComponentType<DataProps> = ({ blockPosts }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderTile = (
    backgroundPicture,
    title,
    description,
    date,
    slug,
    HidePublicationDates,
    index = 0
  ) => {
    const tileStyles = useTileStyles(backgroundPicture)

    return (
      <Link
        to={`/blog/${slug}`}
        className={tileStyles.noTextDecoration}
        key={`block-tile-${index}`}
      >
        <div className={tileStyles.main} key={`tile-${index}`}>
          <div className={tileStyles.item}>
            <h2 className={tileStyles.title}>
              {title.length > 50 ? `${title.substring(0, 50)}...` : title}
            </h2>
            <div className={tileStyles.description}>
              {description?.length > 100
                ? `${description.substring(0, 100)}...`
                : description}
            </div>
            <div className={tileStyles.date}>
              {!HidePublicationDates &&
                date &&
                new Date(date).toLocaleString("cs-CZ", {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                })}
            </div>
          </div>
        </div>
      </Link>
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.title}>{t("HOMEPAGE_BLOG_TITLE")}</div>
      <div className={classes.flexBox}>
        {blockPosts.map((item: HomepageBlogPost, index: number) => {
          return renderTile(
            item.CoverPhoto?.url,
            item.Title,
            item.Perex,
            item.PublicationDate,
            item.Slug,
            item.Categories[0]?.HidePublicationDates,
            index
          )
        })}
      </div>

      <Link
        className={`${classes.btnSecondary} ${classes.btnVisit}`}
        to="/blog"
      >
        {t("HOMEPAGE_BLOG_MORE")}
      </Link>
    </div>
  )
}

export default BlockPanel
